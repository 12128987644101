/**
 * Mortgage Calculator
 *
 * You can changes the default values in config
 *
 * DEV:NOTES
 * Please do not do nay changes in the DO NOT DO ANY CHNAGES block
 * Do import in use project specific import here
 * And play with the render layout structure and keep the params
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { Row, Col, Button, Form } from 'react-bootstrap';
import { filterNumber, numberFormat } from "./util"
import {
  calculate,
  countries,
  // propertyTypes,
  // buyerTypes,
} from "uk-ireland-stampduty-calculator"

// we could user uk-ireland-stampduty-calculator's propertyTypes & buyerTypes
// but key val par diff we could get valus using Object.values but it is not
// supported in IE - https://caniuse.com/#feat=object-values
// so defined our our custom key pairs
const propertyTypes = [
  {
    value: "residential",
    label: "Residential",
  },
  {
    value: "commercial",
    label: "Commercial",
  },
]

const buyerTypes = [
  {
    value: "first",
    label: "First-time Buyer",
  },
  {
    value: "home",
    label: "Mover (not a first-time buyer)",
  },
  {
    value: "investor",
    label: "Second Home or Buy to Let",
  },
]

// use project specific import here

// DO NOT DO ANY CHNAGES - START
const StampdutyCalc = props => {
  
  const currency = props.currency
  const title = props.title

  const [result, setResult] = useState(false)
  const [showerror, setShowerror] = useState(false)

  const [propertyType, setPropertyType] = useState(props.propertyType)
  const [propertyValue, setPropertyValue] = useState(
    currency + numberFormat(filterNumber(props.propertyValue))
  )
  const [buyer, setBuyer] = useState(props.buyerType)

  const formatPrice = str => {
    return currency + str.toLocaleString("en-US")
  }

  const doCalculate = () => {
    const results = calculate(
      filterNumber(propertyValue),
      propertyType,
      countries.ENGLAND,
      buyer
    )
    // effective stamp duty rates
    // Formula => ((tax / price) * 100).
    // Ex: (213750/2500000) * 100 = 8.55%.
    let effectiveRate = (results.tax / filterNumber(propertyValue)) * 100
    effectiveRate = new Intl.NumberFormat("en-US", {}).format(effectiveRate)

    if(effectiveRate === "NaN")
    {
      effectiveRate = 0
    }

    let summaryBands = []
    results.summaryBands.map(result => {
      summaryBands.push(
        result.adjustedRate +
          "% between " +
          formatPrice(result.start) +
          " and " +
          formatPrice(result.end)
      )
    })
    const result = {
      effectiveRate: effectiveRate + "%",
      summaryBands: summaryBands,
      tax: formatPrice(results.tax),
    }
    setResult(result)
  }

  const handleSubmit = event => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'Calculator',
        'formType': title,
        'formId': "Stamp Duty Calculator",
        'formName': "Stamp Duty Calculator",
        'formLabel': "Stamp Duty Calculator"
      });
      const form = event.currentTarget
      event.preventDefault()
      event.stopPropagation()
      if (form.checkValidity() === false) {
        setShowerror(true)
      } else {
        if(filterNumber(propertyValue) == 0) {
          setShowerror(true)
          setResult(false)
        }
        else {
          setShowerror(false)
          doCalculate()
        }
      }
  }

  const handleDeposit = event => {
    setPropertyValue(currency + numberFormat(filterNumber(event.target.value)))
  }

  const handlePropertyType = event => {
    setPropertyType(event.target.value)
  }

  const handleBuyer = event => {
    setBuyer(event.target.value)
  }

  // DO NOT DO ANY CHNAGES - END

  return (
    <div className="main-calc">
    <div className="form-wrapper">
    <div className="form mortgate-form calculator">
      {showerror && (
        <div className="alert-danger">
          <p>* Please enter Property Value amount</p>
        </div>
      )}
      <Form
        name="MortgageCalc"
        noValidate
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="bot-field" />

      <Row>

      {props.tag !== "details" &&
        <Col md={12}>


          <Form.Group controlId="property_type">
            <div className="custom-float custom-float--select">
              <Form.Label>Property Type</Form.Label>
              <Form.Control 
                as="select"
                className="form-control form-select"
                required
                name="property_type"
                placeholder="Property Type"
                onChange={handlePropertyType}
              >
                {propertyTypes.map((item, i) => {
                  return (
                    <option value={item.value} key={i}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Control>
            </div>
          </Form.Group>
        </Col>
    }


        <Col md={props.tag == "details" ? 6 : 12}>
          <Form.Group controlId="deposit">
            <div className="custom-float">
              <Form.Label>Property Value</Form.Label>
              <Form.Control 
                required
                type="text"
                name="deposit"
                value={propertyValue}
                placeholder="Purchase Price"
                onChange={handleDeposit}
              />
            </div>
          </Form.Group>
        </Col>

        

        <Col md={props.tag == "details" ? 6 : 12}>
          <Form.Group controlId="buyer">
            <div className="custom-float custom-float--select">
              <Form.Label>Buyer Type</Form.Label>
              <Form.Control 
                as="select"
                className="form-control form-select"
                required
                name="buyer"
                placeholder="Buyer Type"
                onChange={handleBuyer}
              >
                {buyerTypes.map((item, i) => {
                  return (
                    <option value={item.value} key={i}>
                      {item.label}
                    </option>
                  )
                })}
              </Form.Control>
            </div>
          </Form.Group>
        </Col>
        <Col md={12}>
        <Button type="submit" className="btn btn-pink">
          Calculate
        </Button>
        </Col>
        </Row>
      </Form>
    </div>
    </div>
    <Row>
      <Col md={12}>
      {result && (
        <div className="calc-result">
        <div className="monthly-pay main-result">
            Stamp Duty - <span className="pay" >{result.tax}</span>
        </div>
        <div className="monthly-pay stamp-one">
            <div className="txt d-block">You have to pay stamp duty:</div>
            {result.summaryBands.map((sm, i) => {
                return <div className="stamp-result-1">{sm}</div>
              })}
              <div className="stamp-result-1 pay">
                Your effective stamp duty rate is <div className="val">{parseFloat(result.effectiveRate).toFixed(2)} %</div>
              </div>
        </div>
        </div>
      )}
      </Col>
    </Row>
    </div>
  )
}

StampdutyCalc.defaultProps = {
  propertyType: `residential`,
  propertyValue: `450000`,
  buyerType: `first`,
  currency: process.env.CURRENCY ? process.env.CURRENCY : "£",
}

StampdutyCalc.propTypes = {
  propertyType: PropTypes.string,
  propertyValue: PropTypes.any,
  buyerType: PropTypes.string,
  currency: PropTypes.string,
}

export default StampdutyCalc
