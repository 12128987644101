import { Link } from "gatsby";
import Helmet from 'react-helmet'
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap"


const BudgetBooster = (props) => {

  useEffect(() => {

    setTimeout(() => {

      window.renderHomewiseCalculator({
        targetSelector: '#homewise-calculator',
        type: props.Choose_Menu && props.Choose_Menu[0]?.Alias == "budget-booster-for-over-60s" ? 'budget-booster-page-arun' : 'budget-booster-listing-arun',
        widgetBackgroundColor: '#C2D8DE',
        widgetBorderColor: '#C2D8DE',
        widgetTitleTextColor: '#034051',
        widgetTitleFontWeight: 'bold',
        formTitleTextColor: '#034051',
        formLabelTextColor: '#034051',
        formButtonColor: '#005670',
        formButtonHoverColor: '#c2d8de',
        modalButtonColor: '#005670',
        modalButtonHoverColor: '#c2d8de',
        formButtonTextTransform: 'uppercase',
        formButtonTextColor: '#034051',
        formButtonHoverTextColor: '#034051',
        widgetLogoColored: 'true',
        modalHeaderBackgroundColor: '#034051',
        modalHeaderTitleFontWeight: 'bold',
        modalBodyBackgroundColor: '#ffffff',
        modalBodyTitleTextColor: '#034051',
        modalBodyTitleFontWeight: 'bold',
        modalBodyTextColor: '#4D676F',
        modalButtonTextTransform: 'uppercase',
        modalButtonTextColor: '#fff',
        modalButtonHoverTextColor: '#fff',
        formTitleFontSize: '14px',
        boosterIconLogoColor: '#024959',
        modalLogoColored: 'true',
        boosterIconLogoWidth: '60',
        mobileMediaMaxWidth: '500',
        lineBreakerColor: '#9eb0b5',
        companyName: 'Arun Estates',
        openInNewTab: true,
        initialValues: { price: props.price ? `${props.price}` : '250000.0000' },
      });
    }, 1500)

  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <script src="https://homewise.dabdev.net/js/homewise-calculator-widget.js"></script>
      </Helmet>
      <div className="budget-booster-calculator">
        <div id="homewise-calculator"></div>
      </div>
    </React.Fragment>
  )
}

export default BudgetBooster