/**
 * Rental Yield Calculator
 *
 * You can changes the default values in config
 *
 * DEV:NOTES
 * Please do not do nay changes in the DO NOT DO ANY CHNAGES block
 * Do import in use project specific import here
 * And play with the render layout structure and keep the params
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby";
import { Row, Col, Form, Button } from "react-bootstrap"
import { filterNumber, numberFormat, rentalYield } from "./util"
import { defaultValues } from "./config"

import { BUY_TO_LETS_URL, VALUATION_PAGE_URL, FIND_A_PROPERTY_PAGE_URL } from "../../common/site/constants";
// use project specific import here

// DO NOT DO ANY CHNAGES - START
const RentalyieldCalc = props => {
  const {
    propertyPrice,
    monthlyRent,
    currency,
    instantResult,
    pricePrefix,
    title,
  } = props

  const prefix = pricePrefix ? currency : ""
  const [validated, setValidated] = useState(false)
  const [showerror, setShowerror] = useState(false)
  const [purchasePrice, setPurchasePrice] = useState(
    prefix + numberFormat(propertyPrice)
  )
  const [rent, setRent] = useState(prefix + numberFormat(monthlyRent))
  const [result, setResult] = useState("")

  const handlePrice = event => {
    let val = filterNumber(event.target.value)
    setPurchasePrice(prefix + numberFormat(val))
  }

  const handleRent = event => {
    let d = filterNumber(event.target.value)
    setRent(prefix + numberFormat(d))
  }

  const getResult = (purchasePrice, rent) => {
    let result = rentalYield(filterNumber(purchasePrice), filterNumber(rent))
    setResult(numberFormat(result))
  }

  const handleSubmit = event => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    if (form.checkValidity() === false) {
      setShowerror(true)
      setValidated(true)
    } else {

      if (filterNumber(purchasePrice) == 0 || filterNumber(rent) == 0) {
        setShowerror(true)
      }
      else {

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Calculator',
          'formType': title,
          'formId': "Rental Yield Calculator",
          'formName': "Rental Yield Calculator",
          'formLabel': "Rental Yield Calculator"
        });

        if (filterNumber(purchasePrice) && filterNumber(rent)) {
          getResult(purchasePrice, rent)
        }
      }
    }
  }

  useEffect(() => {
    if (instantResult) {
      getResult(purchasePrice, rent)
    }
  }, [instantResult, purchasePrice, rent])

  // DO NOT DO ANY CHNAGES - END

  return (
    <div className="main-calc">
      <div className="form-wrapper">
        <div className="form mortgate-form calculator">
          {showerror && (
            <div className="alert-danger">
              <p>* All fields are required</p>
            </div>
          )}
          <Form
            name="RentalyieldCalc"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="bot-field" />
            <Row>
              <Col md={props.tag == "details" ? 6 : 12}>
                <Form.Group controlId="purchase_price">
                  <Form.Label>Property Value</Form.Label>
                  <Form.Control
                    className="form-control"
                    required
                    type="text"
                    name="purchase_price"
                    value={purchasePrice}
                    placeholder="Purchase Price"
                    onChange={handlePrice}
                  />
                </Form.Group>
              </Col>
              <Col md={props.tag == "details" ? 6 : 12}>
                <Form.Group controlId="rent">
                  <Form.Label>Monthly Rent**</Form.Label>
                  <Form.Control
                    className="form-control"
                    required
                    type="text"
                    name="monthly_rent"
                    value={rent}
                    placeholder="Monthly Rent"
                    onChange={handleRent}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Button type="submit" className="btn btn-pink">
                  Calculate
                </Button>
              </Col>
              {props.type == "landing" &&
                <Col md={6}>
                  <Link to={BUY_TO_LETS_URL.alias} className="btn btn-pink">
                    Search Buy to Lets
                  </Link>
                </Col>
              }
            </Row>
          </Form>
        </div>
      </div>
      <Row>
        <Col md={12}>
          {result && (
            <div className="calc-result">
              Estimated rental yield <div className="val">{result}%</div>
              {props.type == "landing" &&
                <Row className="mt-4">
                  <Col md={6}>
                    <Link to={VALUATION_PAGE_URL.alias} className="btn btn-secondary">
                      Book a Valuation
                    </Link>
                  </Col>
                  <Col md={6}>
                    <Link to={FIND_A_PROPERTY_PAGE_URL.alias} className="btn btn-secondary">
                      Find a Property
                    </Link>
                  </Col>
                </Row>
              }
            </div>
          )}
        </Col>
      </Row>
    </div>
  )
}

RentalyieldCalc.defaultProps = {
  propertyPrice: defaultValues.PROPERTY_VALUE,
  monthlyRent: defaultValues.MONTHLY_RENT,
  currency: process.env.CURRENCY
    ? process.env.CURRENCY
    : defaultValues.CURRENCY,
  instantResult: defaultValues.INSTANT_RESULT,
  pricePrefix: defaultValues.PRICE_PREFIX,
}

RentalyieldCalc.propTypes = {
  propertyPrice: PropTypes.any,
  monthlyRent: PropTypes.any,
  currency: PropTypes.string,
  instantResult: PropTypes.any,
  pricePrefix: PropTypes.any,
}

export default RentalyieldCalc